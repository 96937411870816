import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import "../styling/css/main.css"

export default function Home({ data }) {
    return (
      <Layout>
     <div style={{zIndex:"50"}}>
<Container >
<Homebackground>

</Homebackground>
<BottomLeft style={{background:"white",height:"32vh",width:"40vw"}}>
    <LogoImage src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/8186_Logo-jkh-1.gif" alt="" srcset="" />
    
    <Content>
      <Title>
        <span style={{color:"#616161", fontSize:"25px", fontWeight:"100"}}>Sri Lanka’s </span>
        <span style={{color:"#616161", fontSize:"25px", fontWeight:"400"}}>Premier</span><br />
        <span style={{color:"#375D78", fontSize:"25px", fontWeight:"400"}}>CONGLOMERATE</span>
      </Title>
      <Description>
        <span style={{color:"#616161", fontSize:"18px", fontWeight:"100"}}>John Keells is an iconic business enterprise with a heritage of over 150 years and a reputation for strength, stability and resilience.</span>
      </Description>
    </Content>

</BottomLeft>

</Container>

<Section1>
  <S1Row1>
    <SubTitle>
      <span style={{color:"#616161", fontSize:"28px"}}>More than just a</span><br />
      <span style={{color:"#375D78", fontSize:"32px", marginLeft: "2px"}}>WORKPLACE</span>
    </SubTitle>
    <Description2>
      <span style={{color:"#444444", fontSize:"18px", fontWeight:"100"}}>We pursue a human resources vision founded on three building blocks - inspiring people, caring for people and leadership, where people are inspired to be the best they can be.</span>
    </Description2>
    <div style={{height: "25%", display:"flex", justifyContent: "flex-end", marginTop: "5%"}}>

      <span style={{color:"#00D1FF", background:"#275E7C", width: "15%", margin: "auto", textAlign: "right", marginRight:"10%", padding: "2%"}}>JOIN OUR <br></br> TEAM</span>
    </div>
  </S1Row1>
  <S1Row2>
    <ImageBox>
      <Image src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/Careers-Running-Girl-1.png" alt="" srcset="" />
    </ImageBox>
    
  </S1Row2>
  
</Section1>

<Section2>

  <S2Row1>

    <S2Row1Col1>
      <div style={{ width: "45%", border: "1px solid #E9E9E9", boxSizing: "border-box", margin: "auto", padding:"2%"}}>
        <div>
          <span> PROFIT / REVENUE</span>
        </div>
        <div>
          <img style={{margin: "auto", width: "95%"}} src="https://keells-2022-do.3cs.website/wp-content/uploads/2022/05/finance_graph.jpg" alt="" />
        </div>

      </div>
    </S2Row1Col1>

    <S2Row1Col2>

    </S2Row1Col2>

  </S2Row1>

  <S2Row2>
    <S2Row2Col1></S2Row2Col1>
    <S2Row2Col2></S2Row2Col2>
  </S2Row2>

</Section2>

<Section3>
    <SubTitle2>
      <span style={{color:"#616161", fontSize:"28px"}}>CSR & </span><br />
      <span style={{color:"#375D78", fontSize:"32px", marginLeft: "2px"}}>SUSTAINABILITY</span>
    </SubTitle2>
    
    <ImageBox2>
      <Image2 src="https://keells-2022-do.3cs.website/wp-content/uploads/2022/05/elephant_csr.png" alt=""></Image2>
    </ImageBox2>

    <Description3>
      < Description3Span>
        <span>We are committed to integrating sustainability throughout our operations and value chain. This strategic outlook is based on the 'triple bottom line' of economic, environmental and social performance, which is reported annually through the Group Integrated Annual Report.</span>
      </Description3Span>
    </Description3>

</Section3>

<Section4>
  <SubTitle3>
    <span style={{color:"#375D78", fontSize:"32px", marginLeft: "2px"}}>GOVERNANCE</span>
  </SubTitle3>
  <Section4Content>
    <Box>
      <span style={{color: "#FFFFFF"}}>Corporate</span><br />
      <span style={{color: "#00D0FF"}}>Governance</span><br />
      <span style={{color: "#FFFFFF"}}>Commentary</span><br />
    </Box>
    <Description4>
      <span>The Group's robust and comprehensive corporate
      governance framework, endeavours to create an
      enabling environment for growth in a structured,
      predictable and sustainable manner. The Group's
      corporate governance philosophy is institutionalised
      across all its business units, and it is this philosophy
      that has continuously created value for all its
      stakeholders, notwithstanding the external
      environment and macro conditions.</span>
    </Description4>
  </Section4Content>
</Section4>

</div>
      </Layout>
    )
  }
  
const Homebackground = styled.div`
  background: url("https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/Home-banner-1.jpg"); 
  background-size: cover;
      height: 80vh;
`

const Container = styled.div`
    position: relative;
    text-align: center;
    color: white;
    z-index: 10;
`

const BottomLeft = styled.div`
  position: absolute;
  bottom: 8vh;
  left: 5vw;
  display: flex;
  flex-direction: row;
`

const LogoImage = styled.img`
  height: 120px;
  padding: 30px;
  width: 15%;
  margin-top: 5%;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`
const Title = styled.div`
  font-style: normal;
  margin-top: 5%;
  margin-right: 5%;
  text-align: right;
`
const Description = styled.div`
  text-align: left;
  margin-top: 5%;
  margin-right: 10%;
`
const Section1= styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 50px;
`
const S1Row1= styled.div`
  width: 65%;

`
const SubTitle= styled.div`
  margin: 10px;
  margin-left: 12%;
  width: 30%;
  text-align: right;
`

const Description2= styled.div`
  width: 50%;
  margin: 10px;
  margin-left: 30%;
`

const S1Row2= styled.div`
  width: 35%;
`
const ImageBox= styled.div`
  width: 50%;
  background:#275E7C;
  height: 100%;
  display: flex;
  justify-content: center;
`
const Image= styled.img`
  width: 150%;
  height: 100%;
`

const Section2= styled.div`
  display: flex;
  flex-direction: row;
`
const S2Row1= styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`
const S2Row1Col1= styled.div`
 
`
const S2Row1Col2= styled.div`
 
`

const S2Row2= styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`
const S2Row2Col1= styled.div`
 
`
const S2Row2Col2= styled.div`
 
`

const Section3= styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`
const SubTitle2= styled.div`
  margin: 10px;
  width: 30%;
  text-align: right;
`
const ImageBox2= styled.div`
  
`
const Image2= styled.img`
  width: 70%;
  margin-top: -5%;
  float: right;
`
const Description3= styled.div`
  
`
const Description3Span = styled.div`
  background: #FFFFFF;
  border: 1px solid #EEEFEF;
  width: 30%;
  margin-left: 15%;
  margin-top: -15%;
  font-size: 15pt;
  color: #616161;
  padding: 3%;
  padding-top: 6%;
  padding-right: 6%;
  text-align: left;
`

const Section4= styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`

const SubTitle3= styled.div`
  text-align: right;
  margin-right: 20%;
`
const Section4Content= styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  margin: auto;
`
const Box= styled.div`
  background: linear-gradient(165.84deg, #356B8D 16.47%, #074155 98.53%);
  width: 20%;
  padding-top: 20%;
  padding-bottom: 5%;
  text-align: right;
  padding-right: 1%;
  font-size: 13pt;
  height: 25%;
  
`
const Description4= styled.div`
  width: 80%;
  color: #616161;
  font-size: 15pt;
  padding: 8%;
  margin-top: 15%;
`